import './bootstrap';

import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;

import hljs from 'highlight.js/lib/core';
import php from 'highlight.js/lib/languages/php';
import html from 'highlight.js/lib/languages/xml';
hljs.registerLanguage('php', php);
hljs.registerLanguage('html', html);

import ClipboardJS from 'clipboard';

import.meta.glob([
    '../images/**',
]);

$(document).ready(function() {
    $('[data-bs-toggle="popover"]').popover();
    $('[data-bs-toggle="tooltip"]').tooltip();
    hljs.highlightAll();

    let clip = new ClipboardJS('.command ~ .input-group-text', {
        target: function(trigger) {
            return trigger.previousElementSibling;
        }
    });

    clip.on('success', (e) => {
        let icon = $(e.trigger).find('.bi');
        icon.removeClass('bi-clipboard').addClass('bi-check');

        setTimeout(() => {
            icon.removeClass('bi-check').addClass('bi-clipboard');
        }, 1000);

        e.clearSelection();
    });
});
